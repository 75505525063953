import Grid from '@mui/material/Grid';
import { Button } from '@mui/material';
import { Gender, PatientParameters, Race } from '../../api/patients';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { StyledTextField } from '../core/input-components/StyledTextField';
import { StyledRadioGroup } from '../core/input-components/StyledRadioGroup';
import { convertLocalToIsoFormat } from '../../utils/dates';
import { DateInput } from '../core/input-components/DateInput';

interface DemographicDataFormProps {
  onSave: (patientParameters: PatientParameters) => void;
  id: string;
  patientParameters?: PatientParameters;
  additionalButtons?: () => React.ReactElement[];
}

const defaultPatientParameters = {
  firstName: '',
  lastName: '',
  birthday: '',
  gender: Gender.FEMALE,
  race: Race.ASIAN,
};

export function DemographicDataForm({
  patientParameters = defaultPatientParameters,
  onSave,
  additionalButtons,
}: DemographicDataFormProps) {
  const { t } = useTranslation('forms');
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm<PatientParameters>({
    mode: 'onBlur',
    defaultValues: patientParameters,
  });

  useEffect(() => {
    if (patientParameters) {
      const fields = patientParameters;
      const keys = Object.keys(fields) as Array<keyof PatientParameters>;
      keys.forEach((f) => setValue(f, fields[f]));
    }
  }, [patientParameters, setValue]);

  const onSubmit = (data: PatientParameters) => {
    onSave({
      ...data,
      birthday: convertLocalToIsoFormat(data.birthday),
    });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <StyledTextField
          name="firstName"
          label={t('firstName', 'First name')}
          register={register('firstName', { required: t<string>('errors.required') })}
          errors={errors}
        />
      </Grid>
      <Grid item xs={4}>
        <StyledTextField
          name="lastName"
          label={t('lastName', 'Last name')}
          register={register('lastName', { required: t<string>('errors.required') })}
          errors={errors}
        />
      </Grid>
      <Grid item xs={4}>
        <DateInput
          name="birthday"
          label={t('birthday', 'Date of birth')}
          register={register}
          errors={errors}
        />
      </Grid>
      <Grid item xs={12}>
        <StyledRadioGroup
          name="gender"
          label={t('gender', 'Gender')}
          options={Object.values(Gender).map((value) => ({
            value,
            label: t('genderValues.' + value),
          }))}
          errors={errors}
          control={control}
        />
      </Grid>
      <Grid item xs={12}>
        <StyledRadioGroup
          name="race"
          label={t('race', 'Race')}
          options={Object.values(Race).map((value) => ({
            value,
            label: t('raceValues.' + value),
          }))}
          errors={errors}
          control={control}
        />
      </Grid>
      <Grid item container spacing={2}>
        <Grid item>
          <Button color="primary" variant="contained" onClick={handleSubmit(onSubmit)}>
            {t('save', 'Save')}
          </Button>
        </Grid>
        {additionalButtons
          ? additionalButtons().map((b, i) => (
              <Grid item key={i}>
                {b}
              </Grid>
            ))
          : null}
      </Grid>
    </Grid>
  );
}
