import React, { ReactNode } from 'react';
import { useMutateSettings, useSavedSettings } from '../api/settings';
import { isRedoxPage } from '../utils/isRedoxPage';

export enum Units {
  Metric = 'metric',
  Imperial = 'imperial',
}

export interface Settings {
  units: Units;
}

interface SettingsProviderProps {
  children: ReactNode;
}

const defaultSettings = {
  units: Units.Imperial,
};

export const SettingsContext = React.createContext({
  settings: defaultSettings,
  saveSettings: async (s: Settings) => {},
});

export function SettingsProvider({ children }: SettingsProviderProps) {
  const { data } = useSavedSettings();
  const mutation = useMutateSettings();

  let settings = defaultSettings;
  if (!isRedoxPage() && data) {
    settings = data;
  }
  const saveSettings = async (settings: Settings) => {
    await mutation.mutate(settings);
  };

  return (
    <SettingsContext.Provider value={{ settings, saveSettings }}>
      {children}
    </SettingsContext.Provider>
  );
}
