import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';

const PREFIX = 'Price';

const classes = {
  container: `${PREFIX}-container`,
  frequency: `${PREFIX}-frequency`,
};

const Root = styled('div')({
  [`&.${classes.container}`]: {
    display: 'flex',
    marginBottom: '10px',
    alignItems: 'center',
  },
  [`& .${classes.frequency}`]: {
    marginLeft: '0.5em',
    color: 'gray',
  },
});

interface PricePros {
  monthlyPrice: string;
}

export default function Price(props: PricePros) {
  return (
    <Root className={classes.container}>
      <Typography variant={'h5'}>{props.monthlyPrice}</Typography>
    </Root>
  );
}
