import { Box, ButtonBase, Divider, Grid, Paper, Typography } from '@mui/material';
import React, { Suspense, useState } from 'react';
import { Add, Close } from '@mui/icons-material';
import { DemographicDataForm } from './DemographicDataForm';
import { PatientParameters } from '../../api/patients';
import { useTranslation } from 'react-i18next';

interface CreatePatientItemProps {
  onCreate: (parameters: PatientParameters) => void;
}
export function CreatePatientItem({ onCreate }: CreatePatientItemProps) {
  const { t } = useTranslation('pages');

  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <Paper elevation={0} sx={{ mt: 1 }} data-testid="create-patient-item">
      <ButtonBase
        sx={{
          width: 1,
          px: 4,
          py: 2,
          '&:hover': {
            background: 'rgba(48, 48, 48, 0.04)',
          },
        }}
        onClick={() => setIsExpanded(!isExpanded)}
        aria-label={isExpanded ? 'collapse' : 'expand'}
      >
        <Grid container justifyContent="space-between" alignItems="center" sx={{ height: '59px' }}>
          <Grid item>
            <Typography variant="h5" component="h2" color="primary">
              {t('patients.createPatientHeading', 'Add a patient')}
            </Typography>
          </Grid>
          <Grid item sx={{ pr: 1.5, display: 'flex' }}>
            {isExpanded ? (
              <Close color="primary" fontSize="large" />
            ) : (
              <Add color="primary" fontSize="large" />
            )}
          </Grid>
        </Grid>
      </ButtonBase>
      {isExpanded && (
        <Box sx={{ px: 4, pb: 2 }}>
          <Divider sx={{ mb: 4 }} />
          <Suspense fallback={null}>
            <DemographicDataForm onSave={onCreate} id="create" />
          </Suspense>
        </Box>
      )}
    </Paper>
  );
}
