import React, { Suspense, useState } from 'react';
import { Patient, PatientParameters, patientsApi } from '../../api/patients';
import { Box, Button, Divider, Grid, IconButton, Paper, Typography } from '@mui/material';
import { DemographicDataForm } from './DemographicDataForm';
import { BarChart, ExpandLess, ExpandMore } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useSettings } from '../../hooks/useSettings';
import { getAgeAtDate } from '../../utils/dates';

interface PatientItemProps {
  patient: Patient;
}

export function PatientItem(props: PatientItemProps) {
  const [isExpanded, setIsExpanded] = useState(false);

  const { helpers } = useSettings();
  const { t } = useTranslation(['forms', 'pages']);

  const birthday = new Date(props.patient.birthday);

  const queryClient = useQueryClient();
  const handleSave = async (newParameters: PatientParameters) => {
    await patientsApi.update({ ...props.patient, ...newParameters });
    await queryClient.invalidateQueries('patients');
  };

  const handleDelete = async () => {
    if (
      window.confirm(
        `Are you sure you want to delete the patient ${props.patient.firstName} ${props.patient.lastName}?`,
      )
    ) {
      await patientsApi.deletePatient(props.patient.id);
      await queryClient.invalidateQueries('patients');
    }
  };

  return (
    <Paper elevation={0} sx={{ mt: 1 }} data-testid={`patient-${props.patient.id}`}>
      <Grid container justifyContent="space-between" alignItems="center" sx={{ px: 4, py: 2 }}>
        <Grid item>
          <Typography variant="h5" component="h2">
            {props.patient.firstName + ' ' + props.patient.lastName}
          </Typography>
          <Typography variant="subtitle1">{`${helpers.formatDate(birthday)} (${getAgeAtDate(
            birthday,
            new Date(),
          )} ${t('pages:patients.years')})`}</Typography>
        </Grid>
        <Grid item>
          <Link to={`/patient/${props.patient.id}/assessment/new`}>
            <IconButton size="large">
              <BarChart color="primary" fontSize="large" />
            </IconButton>
          </Link>
          <IconButton onClick={() => setIsExpanded(!isExpanded)} size="large">
            {isExpanded ? (
              <ExpandLess color="primary" fontSize="large" />
            ) : (
              <ExpandMore color="primary" fontSize="large" />
            )}
          </IconButton>
        </Grid>
      </Grid>
      {isExpanded && (
        <Box sx={{ px: 4, pb: 2 }}>
          <Divider sx={{ mb: 4 }} />
          <Suspense fallback={null}>
            <DemographicDataForm
              patientParameters={{ ...props.patient, birthday: helpers.formatDate(birthday) }}
              onSave={handleSave}
              id={props.patient.id.toString()}
              additionalButtons={() => [
                <Button color="secondary" variant="contained" onClick={handleDelete}>
                  {t('delete', 'Delete')}
                </Button>,
              ]}
            />
          </Suspense>
        </Box>
      )}
    </Paper>
  );
}
