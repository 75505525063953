import { Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { DiabetesMedication, OralAnticoagulant } from '../../../api/assessments';
import { CheckboxWithLabelAndTooltip } from '../../core/input-components/CheckboxWithLabelAndTooltip';
import { useTranslation } from 'react-i18next';
import { Control, FieldErrors, Path } from 'react-hook-form';
import { StyledRadioGroup } from '../../core/input-components/StyledRadioGroup';
import { StyledLabelWithNote } from '../../core/input-components/StyledLabelWithNote';

const PREFIX = 'Medications';

const classes = {
  div: `${PREFIX}-div`,
  hidden: `${PREFIX}-hidden`,
};

const Root = styled('div')({
  [`&.${classes.div}`]: {
    marginTop: '20px',
    pageBreakInside: 'avoid',
  },
  [`& .${classes.hidden}`]: {
    display: 'none',
  },
});

interface Props<T> {
  control: Control<T>;
  errors: FieldErrors;
  showDiabetesMedication: boolean;
}

export function Medications<T>(props: Props<T>) {
  const { t } = useTranslation('forms');

  return (
    <Root className={classes.div}>
      <Typography variant={'h6'}>{t('medications.heading')}</Typography>
      <Grid container spacing={3} xs={12}>
        <Grid item>
          <CheckboxWithLabelAndTooltip
            name={'antiplateletMedication' as Path<T>}
            label={
              <StyledLabelWithNote
                label={t(`medications.antiplateletMedication.label`)}
                tooltip={t(`medications.antiplateletMedication.tooltip`)}
              />
            }
            control={props.control}
          />
        </Grid>
        <Grid item xs={12}>
          <StyledRadioGroup
            isColumn={true}
            name={'oralAnticoagulant' as Path<T>}
            label={t('medications.oralAnticoagulant.label')}
            options={Object.values(OralAnticoagulant).map((v) => ({
              ...t('medications.oralAnticoagulant.values.' + v, { returnObjects: true }),
              value: v,
            }))}
            control={props.control}
            errors={props.errors}
          />
        </Grid>
        <Grid item className={props.showDiabetesMedication ? '' : classes.hidden} xs={12}>
          <StyledRadioGroup
            isColumn={true}
            name={'diabetesMedication' as Path<T>}
            label={t('medications.diabetesMedication.label')}
            options={Object.values(DiabetesMedication).map((v) => ({
              ...t('medications.diabetesMedication.values.' + v, { returnObjects: true }),
              value: v,
            }))}
            control={props.control}
            errors={props.errors}
          />
        </Grid>
      </Grid>
    </Root>
  );
}
