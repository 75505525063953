import { Checkbox, FormControlLabel, ListItem, Typography } from '@mui/material';
import React from 'react';
import { DocumentPopup } from '../../../core/DocumentPopup/DocumentPopup';
import { Recommendation } from '../../../../api/patients';

interface RecommendationItemProps {
  recommendation: Recommendation;
  onToggle: (r: Recommendation) => void;
}

export default function RecommendationItem(props: RecommendationItemProps) {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.onToggle({ ...props.recommendation, applied: event.target.checked });
  };

  return (
    <ListItem>
      <FormControlLabel
        sx={{ alignItems: 'start' }}
        control={
          <Checkbox
            disabled={props.recommendation.disabled}
            checked={props.recommendation.applied}
            name={props.recommendation.key}
            onChange={handleChange}
            color={'primary'}
          />
        }
        label={
          <React.Fragment>
            <Typography fontWeight="bold" sx={{ pt: 0.75 }}>
              {props.recommendation.title}
              <DocumentPopup
                document={{
                  title: props.recommendation.title,
                  sections: props.recommendation.information,
                }}
              />
            </Typography>
            <div>{props.recommendation.text}</div>
          </React.Fragment>
        }
      />
    </ListItem>
  );
}
