import { Checkbox } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import React, { ReactElement } from 'react';
import LabelWithTooltip from './LabelWithTooltip/LabelWithTooltip';
import { Control, Controller, FieldErrors, Path } from 'react-hook-form';

interface CheckboxWithLabelAndTooltipProps<T> {
  name: Path<T>;
  label: string | ReactElement;
  tooltip?: string;
  disabled?: boolean;
  errors?: FieldErrors;
  control: Control<T>;
}

export function CheckboxWithLabelAndTooltip<T>(props: CheckboxWithLabelAndTooltipProps<T>) {
  return (
    <Controller
      name={props.name}
      control={props.control}
      render={({ field: { value, ref, ...field } }) => {
        // prettier-ignore
        // @ts-ignore
        return (<FormControlLabel control={ <Checkbox checked={value} inputRef={ref} {...field} color="primary" disabled={props.disabled} /> }
            label={<LabelWithTooltip label={props.label} tooltipText={props.tooltip} />}
          />
        );
      }}
    />
  );
}
