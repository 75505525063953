import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import rxlogo from './rx_logo.svg';

const PREFIX = 'Footer';

const classes = {
  appBar: `${PREFIX}-appBar`,
  homeButton: `${PREFIX}-homeButton`,
};

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  [`&.${classes.appBar}`]: {
    background: theme.palette.action.active,
    paddingRight: '30px',
    paddingLeft: '30px',
    bottom: 0,
    top: 'auto',
    height: '3rem',
  },

  [`& .${classes.homeButton}`]: {
    fill: theme.palette.primary.main,
  },
}));

export default function Footer() {
  return (
    <StyledAppBar position="relative" className={classes.appBar} elevation={0}>
      <Toolbar variant={'dense'}>
        <Button href={'https://www.tenac.io/privacye5685a58'} color={'primary'}>
          Privacy
        </Button>
        <Button href={'https://www.tenac.io/impressum'} color={'primary'}>
          Impressum
        </Button>
        <div style={{ flex: 1 }} />
        <img src={rxlogo} alt="Rx" style={{ height: '1.3rem', marginRight: '16px' }} />
        <Typography align={'right'} sx={{ color: 'gray', fontWeight: 'normal' }}>
          tenac.io GmbH 2020 ©
        </Typography>
      </Toolbar>
    </StyledAppBar>
  );
}
