import Grid from '@mui/material/Grid';
import React from 'react';
import { StyledTextField } from '../../core/input-components/StyledTextField';
import { FieldErrors, Path, UseFormRegister } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ruleHelper } from './rule-helper';

interface Props<T> {
  register: UseFormRegister<T>;
  errors: FieldErrors;
}

const ALLOWED_VALUES = {
  pulse: {
    min: 40,
    max: 200,
  },
  systolicBloodPressure: {
    min: 85,
    max: 200,
  },
  diastolicBloodPressure: {
    min: 50,
    max: 120,
  },
};

export function PulseBloodPressure<T>(props: Props<T>) {
  const { t } = useTranslation('forms');

  return (
    <>
      <Grid container spacing={5}>
        <Grid item xs={4}>
          <StyledTextField
            name="pulse"
            type="number"
            label={t('vitals.pulse')}
            register={props.register('pulse' as Path<T>, ruleHelper(t, ALLOWED_VALUES, 'pulse'))}
            errors={props.errors}
            adornment="bpm"
          />
        </Grid>
        <Grid item xs={4}>
          <StyledTextField
            name="systolicBloodPressure"
            type="number"
            label={t('vitals.systolicBloodPressure')}
            register={props.register(
              'systolicBloodPressure' as Path<T>,
              ruleHelper(t, ALLOWED_VALUES, 'systolicBloodPressure', true),
            )}
            errors={props.errors}
            adornment="mmHg"
          />
        </Grid>
        <Grid item xs={4}>
          <StyledTextField
            name="diastolicBloodPressure"
            type="number"
            label={t('vitals.diastolicBloodPressure')}
            register={props.register(
              'diastolicBloodPressure' as Path<T>,
              ruleHelper(t, ALLOWED_VALUES, 'diastolicBloodPressure'),
            )}
            errors={props.errors}
            adornment="mmHg"
          />
        </Grid>
      </Grid>
    </>
  );
}
