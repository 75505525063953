import { Button, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { PATIENT_URL } from '../../../config/urlConfiguration';
import Price from '../Price/Price';
import { StyledPaper } from '../../core/StyledPaper/StyledPaper';
import { useTranslation } from 'react-i18next';

const PREFIX = 'ProductPaper';

const classes = {
  paper: `${PREFIX}-paper`,
  list: `${PREFIX}-list`,
  button: `${PREFIX}-button`,
  title: `${PREFIX}-title`,
};

const StyledStyledPaper = styled(StyledPaper)({
  [`&.${classes.paper}`]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '30px',
    paddingBottom: '20px',
    paddingRight: '70px',
    paddingLeft: '70px',
    marginTop: '20px',
    marginBottom: '80px',
  },
  [`& .${classes.list}`]: {
    textAlign: 'center',
    listStyle: 'inside',
    paddingLeft: 0,
    paddingTop: '10px',
    paddingBottom: '10px',
    fontWeight: 'normal',
  },
  [`& .${classes.button}`]: {
    width: '85%',
  },
  [`& .${classes.title}`]: {
    width: '90%',
  },
});

export default function ProductPaper() {
  const { t } = useTranslation('pages');

  return (
    <StyledStyledPaper className={classes.paper}>
      <Typography align={'center'} variant={'h5'} className={classes.title}>
        {t('homePage.productDescription.title')}
      </Typography>
      <ul className={classes.list}>
        {(t('homePage.productDescription.features', { returnObjects: true }) as [string]).map(
          function (item) {
            return <li key={item}>{item}</li>;
          },
        )}
      </ul>
      <Price monthlyPrice={t('homePage.productDescription.monthlyPrice')} />
      <Button
        href="mailto:info@tenac.io?subject=New%20Account%20Request&body=Dear%20Tenac.io%20Team%2C%0D%0AI%20would%20like%20to%20register%20for%20a%20new%20account%20with%20the%20email%20example%40example.com.%0D%0A%0D%0AKind%20Regards%2C%0D%0A"
        variant="contained"
        color={'primary'}
        target="_blank"
        rel="noopener noreferrer"
        className={classes.button}
      >
        {t('homePage.productDescription.registerButton')}
      </Button>
      <Button href={`${PATIENT_URL}`} color={'primary'} className={classes.button}>
        {t('homePage.productDescription.loginButton')}
      </Button>
    </StyledStyledPaper>
  );
}
