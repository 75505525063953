import { Button, Grid, Tooltip, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { CheckboxWithLabelAndTooltip } from '../../core/input-components/CheckboxWithLabelAndTooltip';
import { PATIENT_PAGE_CONFIGURATION, TERMS_OF_USE } from '../../../config/contentConfiguration';
import { DocumentPopup } from '../../core/DocumentPopup/DocumentPopup';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { isRedoxPage } from '../../../utils/isRedoxPage';

const PREFIX = 'ConsentSubmit';

const classes = {
  div: `${PREFIX}-div`,
};

const Root = styled('div')({
  [`&.${classes.div}`]: {
    marginTop: '20px',
  },
});

interface Props {
  submitHandler: () => void;
}

export function ConsentSubmit(props: Props) {
  const { t } = useTranslation('forms');
  const isRedox = isRedoxPage();
  const { control, watch } = useForm({
    defaultValues: {
      consent: isRedox,
      dataAccuracyConfirm: false,
    },
  });

  const consent = watch('consent');
  const dataAccuracyConfirm = watch('dataAccuracyConfirm');
  return (
    <Root className={classes.div}>
      <Typography variant={'h6'}>{t('confirmations.heading')}</Typography>
      <Grid container spacing={3}>
        {!isRedox && (
          <Grid item xs={12}>
            <CheckboxWithLabelAndTooltip
              name="consent"
              label={
                <p>
                  {t('confirmations.consentText.beforeLink')}
                  <DocumentPopup
                    document={TERMS_OF_USE}
                    linkText={t('confirmations.consentText.link')}
                  />
                  {t('confirmations.consentText.afterLink')}
                </p>
              }
              control={control}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <CheckboxWithLabelAndTooltip
            name="dataAccuracyConfirm"
            label={<p>{t('confirmations.dataConfirmation')}</p>}
            control={control}
          />
        </Grid>
        <Grid item xs={6} />
        <Grid item xs={6}>
          <Tooltip title={t<string>('confirmations.consentText.noConsentTooltip')}>
            <span>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={props.submitHandler}
                disabled={!consent || !dataAccuracyConfirm}
              >
                {PATIENT_PAGE_CONFIGURATION.buttons.submit}
              </Button>
            </span>
          </Tooltip>
        </Grid>
      </Grid>
    </Root>
  );
}
