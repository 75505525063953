import React from 'react';
import { Route, Routes } from 'react-router';
import Footer from './core/Footer/Footer';
import Header from './core/Header/Header';
import { HomePage } from './home/HomePage';
import { PatientsList } from './patients-list/PatientsList';
import { PatientProfile } from './patient-profile/PatientProfile';
import { Assessment } from './assessment/Assessment';
import { Box } from '@mui/material';

function App() {
  return (
    <>
      <Header />
      <Box sx={{ minHeight: 'calc(100vh - 3rem)', pt: '3rem' }}>
        <Routes>
          <Route path="/patient/:id/evaluation/:subpage" element={<PatientProfile />} />
          <Route path="/patient/:patientId/assessment/:assessmentId" element={<Assessment />} />
          <Route path="/patient" element={<PatientsList />} />
          <Route path="/redox/assessment/:assessmentId" element={<Assessment />} />
          <Route path="/redox/evaluation/:subpage" element={<PatientProfile />} />
          <Route path="/" element={<HomePage />} />
        </Routes>
      </Box>
      <Footer />
    </>
  );
}

export default App;
