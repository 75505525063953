import React from 'react';
import { useTranslation } from 'react-i18next';
import { ADJUSTED_COLOR, CURRENT_COLOR } from './IndividualRisk';
import InfoTooltip from '../../../core/input-components/InfoTooltip/InfoTooltip';
import Typography from '@mui/material/Typography';

const MIDDLE_COLOR = '#f4ed80';

interface CombinedRiskProps {
  currentRisk: number;
  adjustedRisk: number;
}

const MAX_RISK_VALUE = 260;
const TOTAL_WIDTH = 850;

const BAR_HEIGHT = 150;
const TOTAL_HEIGHT = 300;
const OFFSET_Y = (TOTAL_HEIGHT - BAR_HEIGHT) / 2;

const FONT_SIZE = 40;

export function CombinedRisk(props: CombinedRiskProps) {
  const { t } = useTranslation('pages');

  const currentRiskX = (props.currentRisk / MAX_RISK_VALUE) * TOTAL_WIDTH;
  const adjustedRiskX = (props.adjustedRisk / MAX_RISK_VALUE) * TOTAL_WIDTH;

  const clampTextOffset = (x: number) => Math.min(Math.max(120, x), TOTAL_WIDTH - 120);

  return (
    <div>
      <Typography variant={'h6'}>
        {t('patientProfile.evaluation.risks.consolidatedRisk')}
        <InfoTooltip
          text={
            <>
              <span>{t('patientProfile.evaluation.risks.consolidatedRiskTooltip.text')}</span>
              <ul>
                <li>{t('patientProfile.evaluation.risks.consolidatedRiskTooltip.item1')}</li>
                <li>{t('patientProfile.evaluation.risks.consolidatedRiskTooltip.item2')}</li>
              </ul>
            </>
          }
        />
      </Typography>

      <svg viewBox={`0 0 ${TOTAL_WIDTH} ${TOTAL_HEIGHT}`} xmlns="http://www.w3.org/2000/svg">
        <defs>
          <linearGradient id="gradient" x1="0%" x2="100%">
            <stop offset="5%" style={{ stopColor: ADJUSTED_COLOR }} />
            <stop offset="50%" style={{ stopColor: MIDDLE_COLOR }} />
            <stop offset="95%" style={{ stopColor: CURRENT_COLOR }} />
          </linearGradient>
        </defs>

        <rect width={TOTAL_WIDTH} height={BAR_HEIGHT} y={OFFSET_Y} fill="url(#gradient)" />

        <text x={10} y={TOTAL_HEIGHT - OFFSET_Y - 10} fill="black" fontSize="35">
          {t('patientProfile.evaluation.risks.low')}
        </text>
        <text
          x={TOTAL_WIDTH - 10}
          y={TOTAL_HEIGHT - OFFSET_Y - 10}
          fill="black"
          fontSize="35"
          textAnchor="end"
        >
          {t('patientProfile.evaluation.risks.high')}
        </text>

        <rect
          x={adjustedRiskX}
          width="12"
          height={BAR_HEIGHT + 40}
          y={OFFSET_Y - 20}
          fill={ADJUSTED_COLOR}
          stroke="white"
          strokeWidth="2"
        />
        <text
          x={clampTextOffset(adjustedRiskX)}
          y={FONT_SIZE}
          textAnchor="middle"
          fontSize={FONT_SIZE}
          fill="black"
        >
          {t('patientProfile.evaluation.risks.adjustedRisk')}
        </text>

        <rect
          x={currentRiskX}
          width="12"
          height={BAR_HEIGHT + 40}
          y={OFFSET_Y - 20}
          fill={CURRENT_COLOR}
          stroke="white"
          strokeWidth="2"
        />
        <text
          x={clampTextOffset(currentRiskX)}
          y={TOTAL_HEIGHT - 5}
          textAnchor="middle"
          fontSize={FONT_SIZE}
          fill="black"
        >
          {t('patientProfile.evaluation.risks.currentRisk')}
        </text>
      </svg>
    </div>
  );
}
