import { Button, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ExitToApp } from '@mui/icons-material';
import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import logout from '../../../api/logout';
import { Settings } from '../../Settings';
import { isRedoxPage } from '../../../utils/isRedoxPage';
import HelpMenu from './HelpMenu';

const PREFIX = 'Header';

const classes = {
  appBar: `${PREFIX}-appBar`,
  toolBar: `${PREFIX}-toolBar`,
  homeButton: `${PREFIX}-homeButton`,
  spacer: `${PREFIX}-spacer`,
};

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  [`&.${classes.appBar}`]: {
    background: theme.palette.action.active,
    height: '3rem',
  },

  [`& .${classes.toolBar}`]: {
    justifyContent: 'space-between',
  },

  [`& .${classes.homeButton}`]: {
    fontFamily: 'Rounded Elegance',
    fontSize: '1.5em',
    padding: 0,
    marginLeft: '20px',
  },

  [`& .${classes.spacer}`]: {
    flexGrow: 1,
  },
}));

export default function Header() {
  const handleLogoutClick = () => {
    logout();
  };

  return (
    <StyledAppBar position="fixed" className={classes.appBar} elevation={0}>
      <Toolbar variant={'dense'} className={classes.toolBar}>
        <div className={classes.spacer}>
          <Button href={'https://www.tenac.io/'} color={'primary'} className={classes.homeButton}>
            tenac.io
          </Button>
        </div>
        <HelpMenu />
        {!isRedoxPage() && <Settings />}
        <IconButton onClick={handleLogoutClick} color={'primary'} sx={{ ml: 3, p: 0 }} size="large">
          <ExitToApp />
        </IconButton>
      </Toolbar>
    </StyledAppBar>
  );
}
