export interface ValueConfiguration {
  information?: string;
  title?: string;
}

export interface FieldConfiguration {
  title: string;
  adornment?: string;
  information?: string;
  valueConfiguration?: { [key: string]: ValueConfiguration };
}

export interface FormConfiguration {
  age: FieldConfiguration;
  antiplateletMedication: FieldConfiguration;
  atrialFibrillation: FieldConfiguration;
  historyOfBleeding: FieldConfiguration;
  coronaryArteryDisease: FieldConfiguration;
  carotidArteryDisease: FieldConfiguration;
  chronicKidneyDisease: FieldConfiguration;
  dementia: FieldConfiguration;
  diabetes: FieldConfiguration;
  hypertension: FieldConfiguration;
  diastolicBloodPressure: FieldConfiguration;
  gender: FieldConfiguration;
  heartFailure: FieldConfiguration;
  height: FieldConfiguration;
  oralAnticoagulant: FieldConfiguration;
  peripheralArteryDisease: FieldConfiguration;
  pulse: FieldConfiguration;
  race: FieldConfiguration;
  currentSmoker: FieldConfiguration;
  historyOfStroke: FieldConfiguration;
  systolicBloodPressure: FieldConfiguration;
  weight: FieldConfiguration;
}

export interface BarChartConfiguration {
  riskCategories: {
    mortality: string;
    stroke: string;
    bleeding: string;
  };
  historyCategories: {
    before: string;
    after: string;
  };
}

export interface HeatChartConfiguration {
  axisLabelLow: string;
  axisLabelHigh: string;
  before: string;
  after: string;
}

export interface DiagramConfiguration {
  header: {
    title: string;
    explanation: string;
  };
  barChart: BarChartConfiguration;
  heatChart: HeatChartConfiguration;
}

export interface PatientPageConfiguration {
  title: string;
  sectionTitles: {
    basicData: string;
    vitals: string;
    medicalHistory: string;
    medications: string;
    recommendations: string;
  };
  buttons: {
    feedback: string;
    reset: string;
    submit: string;
    print: string;
  };
}
export interface ValidationConfiguration {
  tooHighError: string;
  tooLowError: string;
  bloodPressure: string;
}

export enum DocumentSectionType {
  title = 'title',
  paragraph = 'paragraph',
  boldParagraph = 'boldParagraph',
  link = 'link',
  text = 'text',
}

export interface DocumentSection {
  type: DocumentSectionType;
  content: string;
}

export interface DocumentConfiguration {
  title: string;
  sections: Array<DocumentSection>;
}

export interface ContentConfiguration {
  formConfiguration: FormConfiguration;
  diagramConfiguration: DiagramConfiguration;
  patientPageConfiguration: PatientPageConfiguration;
  validationConfiguration: ValidationConfiguration;
  termsOfUse: DocumentConfiguration;
  riskCalculatorBackgrounder: DocumentConfiguration;
}
