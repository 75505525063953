import React from 'react';
import { Container, Paper } from '@mui/material';
import { useParams } from 'react-router-dom';
import { usePatientById } from '../../api/patients';
import { Header } from './Header';
import { Evaluation } from './evaluation/Evaluation';
import { useURLQuery } from '../../hooks/useURLQuery';
import { isRedoxPage } from '../../utils/isRedoxPage';
import { RedoxEvaluation } from './evaluation/RedoxEvaluation';

interface RouteParams {
  id: string;
  subpage: string;
}

export function PatientProfile() {
  const { id: patientId } = useParams() as RouteParams;
  const query = useURLQuery();
  const sessionId = query.get('session') || '';
  const id = isRedoxPage() ? sessionId : patientId;

  const { isLoading, isError, data: patient, error } = usePatientById(id);

  if (isLoading) return <div>Loading...</div>;

  if (isError || !patient) return <div>Error: {error?.message}</div>;

  /*
  Trend page is currently disabled for FDA approval.
  Before it is re-enabled, it will need to be adapted to work with
  the Redox flow as well (same way as the Evaluation component).
   */

  return (
    <Container maxWidth="md" sx={{ paddingTop: '3rem', paddingBottom: '3rem' }}>
      <Paper elevation={0} sx={{ margin: '1rem' }}>
        <Header name={`${patient.firstName} ${patient.lastName}`} id={id} />
        {isRedoxPage() ? <RedoxEvaluation id={id} patient={patient} /> : <Evaluation id={id} />}
      </Paper>
    </Container>
  );
}
