import React from 'react';
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { Control, Controller, FieldErrors, Path } from 'react-hook-form';
import { StyledLabelWithNote } from './StyledLabelWithNote';

interface RadioOption {
  value: string;
  label: string;
  tooltip?: string;
}

interface StyledRadioGroupProps<T> {
  name: Path<T>;
  label: string;
  options: RadioOption[];
  errors: FieldErrors;
  control: Control<T>;
  isColumn?: boolean;
}

export function StyledRadioGroup<T>(props: StyledRadioGroupProps<T>) {
  return (
    <FormControl error={props.errors[props.name]?.message}>
      <FormLabel>{props.label}</FormLabel>
      <Controller
        name={props.name}
        render={({ field }) => (
          <RadioGroup {...field} row={!Boolean(props.isColumn)}>
            {props.options.map(({ value, label, tooltip }) => (
              <FormControlLabel
                key={value}
                value={value}
                control={<Radio color="primary" />}
                label={
                  tooltip === undefined || tooltip === '' ? (
                    label
                  ) : (
                    <StyledLabelWithNote label={label} tooltip={tooltip} />
                  )
                }
              />
            ))}
          </RadioGroup>
        )}
        control={props.control}
      />
      <FormHelperText>{props.errors[props.name]?.message}</FormHelperText>
    </FormControl>
  );
}
