import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { Container, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import { PatientParameters, patientsApi } from '../../api/patients';
import { CreatePatientItem } from './CreatePatientItem';
import { Search } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useQueryClient } from 'react-query';
import { List } from './List';

const PREFIX = 'PatientsList';

const classes = {
  searchField: `${PREFIX}-searchField`,
  container: `${PREFIX}-container`,
};

const StyledContainer = styled(Container)({
  [`& .${classes.searchField}`]: {
    margin: '2rem 0rem',
  },
  [`&.${classes.container}`]: {
    paddingTop: '3rem',
    paddingBottom: '3rem',
  },
});

export function PatientsList() {
  const { t } = useTranslation('pages');
  const navigate = useNavigate();

  const [query, setQuery] = useState('');
  const [searchTerm, setSearchTerm] = useState('');

  const queryClient = useQueryClient();

  const handleCreate = async (parameters: PatientParameters) => {
    const patient = await patientsApi.createPatient(parameters);
    await queryClient.invalidateQueries('patients');
    navigate(`/patient/${patient.id}/assessment/new`);
  };

  return (
    <StyledContainer maxWidth="md" className={classes.container}>
      <Typography variant="h3" component="h1">
        {t('patients.heading', 'Please select a patient')}
      </Typography>
      <TextField
        id="patients-search"
        className={classes.searchField}
        value={query}
        label={t('patients.searchInputLabel', 'Search for a patient')}
        onChange={(event) => setQuery(event.target.value)}
        onKeyPress={(event) => (event.key === 'Enter' ? setSearchTerm(query) : null)}
        variant="filled"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={() => setSearchTerm(query)} size="large">
                <Search />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <CreatePatientItem onCreate={handleCreate} />
      <List searchTerm={searchTerm} />
    </StyledContainer>
  );
}
