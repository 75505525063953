import React, { ReactElement } from 'react';
import InfoTooltip from '../InfoTooltip/InfoTooltip';

interface LabelWithTooltipProps {
  label: string | ReactElement;
  tooltipText?: string;
}

export default function LabelWithTooltip(props: LabelWithTooltipProps) {
  return (
    <React.Fragment>
      <React.Fragment>{props.label}</React.Fragment>
      {props.tooltipText ? <InfoTooltip text={props.tooltipText} /> : null}
    </React.Fragment>
  );
}
