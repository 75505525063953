import { useContext } from 'react';
import { Settings, SettingsContext, Units } from '../context/SettingsProvider';

const getHelpers = (settings: Settings) => ({
  formatDate(date: Date): string {
    const locale = settings.units === Units.Metric ? 'de-DE' : 'en-US';

    return date.toLocaleDateString(locale);
  },
});

export function useSettings() {
  const { settings, saveSettings } = useContext(SettingsContext);

  return {
    settings,
    saveSettings,
    helpers: getHelpers(settings),
  };
}
