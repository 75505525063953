import { Box } from '@mui/material';
import Container from '@mui/material/Container';
import React from 'react';
import GeneralDescription from './GeneralDescription/GeneralDescription';
import ProductPaper from './ProductPaper/ProductPaper';
import Image from '../../img/background.png'; // Import using relative path

export function HomePage() {
  return (
    <Box
      sx={{
        backgroundImage: `url(${Image})`,
        backgroundSize: 'cover',
        height: 'calc(100vh - 6rem)',
      }}
    >
      <Container sx={{ width: 520, py: 5 }}>
        <GeneralDescription />
        <ProductPaper />
      </Container>
    </Box>
  );
}
