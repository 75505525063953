import { Box, CircularProgress, Dialog, DialogContent, DialogTitle } from '@mui/material';
import React, { PropsWithChildren } from 'react';

export type LoadingDialogState = 'closed' | 'loading' | 'finished' | 'error';

interface LoadingDialogProps {
  state: LoadingDialogState;
  onClose: () => void;
  title: string;
}

export function LoadingDialog(props: PropsWithChildren<LoadingDialogProps>) {
  return (
    <Dialog open={props.state !== 'closed'} maxWidth="md" fullWidth={true} onClose={props.onClose}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          m: 'auto',
          width: 'fit-content',
          alignItems: 'center',
          pt: 1,
          pb: 3,
        }}
      >
        <DialogTitle sx={{ color: props.state === 'error' ? 'error.main' : 'text.primary' }}>
          {props.title}
        </DialogTitle>
        <DialogContent sx={{ p: 2 }}>
          {props.state === 'loading' && <CircularProgress />}

          {props.children !== undefined && props.children}
        </DialogContent>
      </Box>
    </Dialog>
  );
}
