import React, { useState } from 'react';
import { Button, Typography } from '@mui/material';
import { LoadingDialog, LoadingDialogState } from '../../core/LoadingDialog/LoadingDialog';
import { Evaluation } from './Evaluation';
import { saveToRedox } from '../../../api/redox';
import { useTranslation } from 'react-i18next';
import { AxiosError } from 'axios';
import { generateOptiCorPDFReport } from '../../../utils/pdfReportGenerator';
import { EvaluationResult, Patient } from '../../../api/patients';

interface RedoxEvaluationProps {
  id: string;
  patient: Patient;
}

const supportEmail = 'support@tenac.io';

export function RedoxEvaluation({ id, patient }: RedoxEvaluationProps) {
  const { t } = useTranslation('pages', { keyPrefix: 'patientProfile.evaluation.redoxLoading' });

  const [loading, setLoading] = useState<{ state: LoadingDialogState; msg: string; err?: string }>({
    state: 'closed',
    msg: '',
  });

  const saveAndClose = async (evalData: EvaluationResult) => {
    setLoading({ state: 'loading', msg: t('generating') });

    const pdfBlob = await generateOptiCorPDFReport(evalData, patient);
    const data = new FormData();
    data.append('optiCorPDFReport', pdfBlob);

    setLoading({ state: 'loading', msg: t('saving') });
    try {
      await saveToRedox(id, data);
      setLoading({ state: 'finished', msg: t('saved') });
    } catch (e) {
      const err = e as AxiosError;
      setLoading({ state: 'error', msg: t('error'), err: `${err.response?.data.message}` });
    }
  };

  const closeLoadingDialog = () => setLoading({ ...loading, state: 'closed' });

  return (
    <>
      <Evaluation id={id} saveAndClose={saveAndClose} />
      <LoadingDialog
        state={loading.state}
        onClose={() => loading.state !== 'loading' && closeLoadingDialog()}
        title={loading.msg}
      >
        {loading.state === 'finished' && (
          <Typography align="center">
            {t('savedSuccessfully')} <br />
            {t('thxForUsing')}
            <Typography variant="h6">{t('closeNow')}</Typography>
          </Typography>
        )}
        {loading.state === 'error' && (
          <Typography align="center">
            {`${loading.err}`} <br />
            {t('contactSupportAt')}
            <a href={`mailto:${supportEmail}`}> {supportEmail} </a> . <br />
            <Button variant="contained" onClick={closeLoadingDialog} sx={{ m: 1 }}>
              {t('close')}
            </Button>
          </Typography>
        )}
      </LoadingDialog>
    </>
  );
}
