import { List, ListItem, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { CheckboxWithLabelAndTooltip } from '../../core/input-components/CheckboxWithLabelAndTooltip';
import { useTranslation } from 'react-i18next';
import { Control, FieldErrors, Path } from 'react-hook-form';

const PREFIX = 'MedicalHistory';

const classes = {
  div: `${PREFIX}-div`,
  list: `${PREFIX}-list`,
};

const Root = styled('div')({
  [`&.${classes.div}`]: {
    marginTop: '20px',
    pageBreakInside: 'avoid',
  },
  [`& .${classes.list}`]: {
    columnCount: 2,
  },
});

interface Props<T> {
  control: Control<T>;
  errors: FieldErrors;
}

export default function MedicalHistory<T>(props: Props<T>) {
  const { t } = useTranslation('forms');

  const fields = [
    'coronaryArteryDisease',
    'heartFailure',
    'carotidArteryDisease',
    'peripheralArteryDisease',
    'historyOfStroke',
    'transientIschemicAttack',
    'thromboembolism',
    'historyOfBleeding',
    'chronicKidneyDisease',
    'diabetes',
    'hypertension',
    'currentSmoker',
    'dementia',
  ];

  return (
    <Root className={classes.div}>
      <Typography variant={'h6'}>{t('medicalHistory.heading')}</Typography>
      <List disablePadding={true} className={classes.list} dense>
        <ListItem disableGutters={true}>
          <CheckboxWithLabelAndTooltip
            name={'atrialFibrillation' as Path<T>}
            label={t(`medicalHistory.atrialFibrillation.label`)}
            tooltip={t(`medicalHistory.atrialFibrillation.tooltip`)}
            disabled
            control={props.control}
          />
        </ListItem>
        {fields.map((field) => (
          <ListItem disableGutters={true} key={field}>
            <CheckboxWithLabelAndTooltip
              name={field as Path<T>}
              label={t(`medicalHistory.${field}.label`)}
              tooltip={t(`medicalHistory.${field}.tooltip`)}
              control={props.control}
            />
          </ListItem>
        ))}
      </List>
    </Root>
  );
}
