import axios, { AxiosError } from 'axios';
import { PATIENTS_URL } from '../config/urlConfiguration';
import { RiskComponents, Risks } from '../types/risk';
import { useQuery } from 'react-query';
import { DocumentSection } from '../types/content-configuration';
import { AssessmentParameters } from './assessments';
import { isRedoxPage } from '../utils/isRedoxPage';
import { getRedoxAdjustedRisks, getRedoxEvaluationResult, getRedoxPatient } from './redox';

/*
t('forms:genderValues.female', 'Female')
t('forms:genderValues.male', 'Male')
 */
export enum Gender {
  FEMALE = 'female',
  MALE = 'male',
}

/*
t('forms:raceValues.asian', 'Asian')
t('forms:raceValues.black', 'Black')
t('forms:raceValues.caucasian', 'Caucasian')
t('forms:raceValues.hispanic', 'Hispanic')
t('forms:raceValues.mixed', 'Mixed')
t('forms:raceValues.other', 'Other')
 */
export enum Race {
  ASIAN = 'asian',
  BLACK = 'black',
  CAUCASIAN = 'caucasian',
  HISPANIC = 'hispanic',
  MIXED = 'mixed',
  OTHER = 'other',
  UNKNOWN = 'unknown',
}

export interface PatientParameters {
  firstName: string;
  lastName: string;
  birthday: string;
  gender: Gender;
  race: Race;
}

export interface Patient extends PatientParameters {
  id: number;
  userId: number;
}

export interface RiskParameters extends AssessmentParameters {
  age: number;
  gender: Gender;
  race: Race;
}

function getParameters({
  firstName,
  lastName,
  birthday,
  gender,
  race,
}: Patient): PatientParameters {
  return {
    firstName,
    lastName,
    birthday,
    gender,
    race,
  };
}

async function createPatient(patientParameters: PatientParameters): Promise<Patient> {
  return (await axios.post(PATIENTS_URL, patientParameters)).data;
}

async function deletePatient(id: number): Promise<void> {
  return (await axios.delete(`${PATIENTS_URL}/${id}`)).data;
}

async function update(patient: Patient): Promise<Patient> {
  return (await axios.put(`${PATIENTS_URL}/${patient.id}`, getParameters(patient))).data;
}

async function getPatientById(id: string): Promise<Patient> {
  return (await axios.get(`${PATIENTS_URL}/${id}`)).data;
}

export function usePatientById(id: string) {
  return useQuery<Patient, AxiosError>(['patient', id], () =>
    isRedoxPage() ? getRedoxPatient(id) : getPatientById(id),
  );
}

async function getSearchResult(name: string): Promise<Patient[]> {
  return (await axios.get(`${PATIENTS_URL}?name=${name}`)).data;
}

export function usePatientsSearch(name: string) {
  return useQuery<Patient[], AxiosError>(['patients', name], () => getSearchResult(name));
}

export interface Recommendation {
  key: string;
  title: string;
  information: Array<DocumentSection>;
  text: string;
  disabled: boolean;
  applied: boolean;
  recommendedParameters: Partial<RiskParameters>;
}

export interface EvaluationResult {
  date: Date;
  currentRisks: Risks;
  adjustedRisks: Risks;
  recommendations: Recommendation[];
}

interface TrendComponent {
  date: Date;
  risks: RiskComponents;
}

async function getEvaluationResult(id: string): Promise<EvaluationResult> {
  const result = (await axios.get(`${PATIENTS_URL}/${id}/evaluate`)).data;
  return {
    ...result,
    date: new Date(result.date),
  };
}

export function useEvaluationResult(id: string) {
  return useQuery<EvaluationResult, AxiosError>(['patient', id, 'evaluate'], () =>
    isRedoxPage() ? getRedoxEvaluationResult(id) : getEvaluationResult(id),
  );
}

async function getAdjustedRisks(id: string, appliedRecommendations: string[]) {
  return (
    await axios.get(`${PATIENTS_URL}/${id}/adjustedRisks`, {
      params: {
        appliedRecommendations,
      },
    })
  ).data;
}

export function useAdjustedRisks(id: string, recommendations: Recommendation[], enabled: boolean) {
  const appliedRecommendations = recommendations.filter((r) => r.applied).map((r) => r.key);
  return useQuery<Risks, AxiosError>(
    ['patient', id, 'adjustedRisks', appliedRecommendations],
    () =>
      isRedoxPage()
        ? getRedoxAdjustedRisks(id, appliedRecommendations)
        : getAdjustedRisks(id, appliedRecommendations),
    { enabled },
  );
}

async function getTrend(patientId: number): Promise<TrendComponent[]> {
  const result = await axios.get(`${PATIENTS_URL}/${patientId}/trend`);
  return result.data.map(({ date, risks }: { date: string; risks: RiskComponents }) => ({
    date: new Date(date),
    risks: {
      mortality: (risks.mortality * 100).toFixed(1),
      stroke: (risks.stroke * 100).toFixed(1),
      bleeding: (risks.bleeding * 100).toFixed(1),
    },
  }));
}

export function useTrend(patientId: number) {
  return useQuery<TrendComponent[], AxiosError>(['patient', patientId, 'trend'], () =>
    getTrend(patientId),
  );
}

export const patientsApi = {
  createPatient,
  deletePatient,
  update,
};
