import axios, { AxiosError } from 'axios';
import { ASSESSMENTS_URL, PATIENTS_URL } from '../config/urlConfiguration';
import { useQuery } from 'react-query';
import { isRedoxPage } from '../utils/isRedoxPage';
import { getRedoxLatestAssessment } from './redox';

export enum OralAnticoagulant {
  NONE = 'none',
  NOAC = 'noac',
  VKA = 'vka',
}

export enum DiabetesMedication {
  NONE = 'none',
  PROVEN_GLP1RA = 'proven_glp1ra',
  OTHER_GLP1RA = 'other_glp1ra',
  SGLT2 = 'sglt2',
}

export interface AssessmentParameters {
  weight: number | null;
  height: number | null;
  pulse: number | null;
  systolicBloodPressure: number;
  diastolicBloodPressure: number | null;

  atrialFibrillation: boolean;
  coronaryArteryDisease: boolean;
  heartFailure: boolean;
  carotidArteryDisease: boolean;
  peripheralArteryDisease: boolean;
  historyOfStroke: boolean;
  historyOfBleeding: boolean;
  chronicKidneyDisease: boolean; // can be null for backend, but not yet implemented in frontend
  diabetes: boolean;
  hypertension: boolean;
  currentSmoker: boolean; // can be null for backend, but not yet implemented in frontend
  dementia: boolean;

  antiplateletMedication: boolean;
  oralAnticoagulant: OralAnticoagulant;
  diabetesMedication: DiabetesMedication;
}

export interface Assessment extends AssessmentParameters {
  id: number;
  patientId: number;
  createdAt: Date;
}

async function create(assessment: Partial<AssessmentParameters>, patientId: number) {
  return (
    await axios.post(ASSESSMENTS_URL, {
      patientId,
      assessment,
    })
  ).data;
}

async function update(assessment: Assessment): Promise<Assessment> {
  return (await axios.put(`${ASSESSMENTS_URL}/${assessment.id}`, assessment)).data;
}

async function getLatestAssessment(patientId: string): Promise<Assessment> {
  return (await axios.get(`${PATIENTS_URL}/${patientId}/latestAssessment`)).data;
}

export function useLatestAssessment(id: string) {
  return useQuery<Assessment, AxiosError>(['patient', id, 'latestAssessment'], () =>
    isRedoxPage() ? getRedoxLatestAssessment(id) : getLatestAssessment(id),
  );
}

export const assessmentsApi = {
  create,
  update,
};
