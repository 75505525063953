import React from 'react';
import { MedicalData } from './MedicalData';
import { AssessmentParameters, assessmentsApi, useLatestAssessment } from '../../api/assessments';
import { useNavigate } from 'react-router';
import { redoxApi } from '../../api/redox';
import { isRedoxPage } from '../../utils/isRedoxPage';
import { useSearchParams } from 'react-router-dom';

interface PrefilledLatestAssessmentProps {
  id: string;
}

export function PrefilledLatestAssessment(props: PrefilledLatestAssessmentProps) {
  const { isLoading, isError, data, error } = useLatestAssessment(props.id);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  if (isLoading) return <div>Loading...</div>;

  if (isError || !data) return <div>Error: {error?.message}</div>;

  const onUpdate = async (newParams: AssessmentParameters) => {
    await assessmentsApi.update({ ...data, ...newParams });
    navigate(`/patient/${props.id}/evaluation/risk`);
  };

  const onRedoxUpdate = async (newParams: AssessmentParameters) => {
    await redoxApi.updateAssessment(props.id, { ...data, ...newParams });
    navigate(`/redox/evaluation/risk${searchParams.toString()}`);
  };

  return <MedicalData onSubmit={isRedoxPage() ? onRedoxUpdate : onUpdate} prefilledValues={data} />;
}
