import React from 'react';
import { usePatientsSearch } from '../../api/patients';
import { PatientItem } from './PatientItem';
import { useTranslation } from 'react-i18next';

interface ListProps {
  searchTerm: string;
}

export function List(props: ListProps) {
  const { isLoading, isError, data, error } = usePatientsSearch(props.searchTerm);
  const { t } = useTranslation('pages');

  if (isLoading) return <div>Loading...</div>;

  if (isError || !data) return <div>Error: {error?.message}</div>;

  if (data.length === 0) return <div>{t('patients.noPatientsFound')}</div>;

  return (
    <>
      {data.map((patient) => (
        <PatientItem key={patient.id} patient={patient} />
      ))}
    </>
  );
}
