import React from 'react';
import { useNavigate } from 'react-router';
import { redoxApi, useRedoxPrefilledAssessment } from '../../api/redox';
import { ImperialForm, ImperialParameters } from './ImperialForm';
import { convertImperialToMetricUnits } from '../../utils/units';
import { useSearchParams } from 'react-router-dom';

interface PrefilledRedoxAssessmentProps {
  sessionId: string;
}

export function PrefilledRedoxAssessment(props: PrefilledRedoxAssessmentProps) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { isLoading, isError, data, error } = useRedoxPrefilledAssessment(props.sessionId);

  if (isLoading) return <div>Loading...</div>;

  if (isError || !data) return <div>Error: {error?.message}</div>;

  const onCreateAssessment = async (params: ImperialParameters) => {
    await redoxApi.createAssessment(props.sessionId, convertImperialToMetricUnits(params));
    navigate(`/redox/evaluation/risk?${searchParams.toString()}`);
  };

  return <ImperialForm onSubmit={onCreateAssessment} prefilledValues={data.parameters} />;
}
