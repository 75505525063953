import * as contentConfiguration from './content.json';
import {
  BarChartConfiguration,
  ContentConfiguration,
  DiagramConfiguration,
  DocumentConfiguration,
  FormConfiguration,
  HeatChartConfiguration,
  PatientPageConfiguration,
  ValidationConfiguration,
} from '../types/content-configuration';

export const FORM_CONFIGURATION: FormConfiguration = (contentConfiguration as ContentConfiguration)
  .formConfiguration;

export const DIAGRAM_CONFIGURATION: DiagramConfiguration = (
  contentConfiguration as ContentConfiguration
).diagramConfiguration;

export const BAR_CHART_CONFIGURATION: BarChartConfiguration = (
  contentConfiguration as ContentConfiguration
).diagramConfiguration.barChart;

export const HEAT_CHART_CONFIGURATION: HeatChartConfiguration = (
  contentConfiguration as ContentConfiguration
).diagramConfiguration.heatChart;

export const PATIENT_PAGE_CONFIGURATION: PatientPageConfiguration = (
  contentConfiguration as ContentConfiguration
).patientPageConfiguration;

export const VALIDATION_CONFIGURATION: ValidationConfiguration = (
  contentConfiguration as ContentConfiguration
).validationConfiguration;

export const TERMS_OF_USE: DocumentConfiguration = (contentConfiguration as ContentConfiguration)
  .termsOfUse;

export const RISK_CALCULATOR_BACKGROUNDER = (contentConfiguration as ContentConfiguration)
  .riskCalculatorBackgrounder;
