import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import { useForm } from 'react-hook-form';
import { StyledRadioGroup } from './core/input-components/StyledRadioGroup';
import { useTranslation } from 'react-i18next';
import { Settings as SettingsInterface, Units } from '../context/SettingsProvider';
import { useSettings } from '../hooks/useSettings';

export function Settings() {
  const [open, setOpen] = useState(false);
  const { settings, saveSettings } = useSettings();
  const { t } = useTranslation(['settings', 'forms']);
  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm<SettingsInterface>({
    defaultValues: settings,
  });

  const handleClickOpen = () => {
    const keys = Object.keys(settings) as Array<keyof SettingsInterface>;
    keys.forEach((f) => setValue(f, settings[f]));
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = async (newSettings: SettingsInterface) => {
    await saveSettings({ ...settings, ...newSettings });
    handleClose();
  };

  const unitsOptions = [
    { value: Units.Metric, label: t('units.values.metric') },
    { value: Units.Imperial, label: t('units.values.imperial') },
  ];

  return (
    <>
      <IconButton color="primary" onClick={handleClickOpen} size="large">
        <SettingsIcon />
      </IconButton>
      <Dialog open={open} onClose={handleClose} keepMounted={true}>
        <DialogTitle>{t('heading')}</DialogTitle>
        <DialogContent>
          <StyledRadioGroup
            name="units"
            label={t('units.label')}
            options={unitsOptions}
            errors={errors}
            control={control}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="neutral">
            {t('forms:cancel')}
          </Button>
          <Button onClick={handleSubmit(handleSave)} color="primary" variant="contained">
            {t('forms:save')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
