import { Divider, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useEffect } from 'react';
import { Medications } from './FormPartials/Medications';
import { PulseBloodPressure } from './FormPartials/PulseBloodPressure';
import MedicalHistory from './FormPartials/MedicalHistory';
import { AssessmentParameters } from '../../api/assessments';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSettings } from '../../hooks/useSettings';
import { ConsentSubmit } from './FormPartials/ConsentSubmit';
import { MetricWeightHeight } from './FormPartials/MetricWeightHeight';
import { DEFAULT_ASSESSMENT_VALUES } from './MedicalData';

const PREFIX = 'MetricForm';

const classes = {
  container: `${PREFIX}-container`,
  heading: `${PREFIX}-heading`,
  divider: `${PREFIX}-divider`,
  grid: `${PREFIX}-grid`,
};

const Root = styled('div')({
  [`&.${classes.container}`]: {
    padding: '2rem',
  },
  [`& .${classes.heading}`]: {
    marginBottom: '1.5rem',
  },
  [`& .${classes.divider}`]: {
    marginTop: '30px',
  },
  [`& .${classes.grid}`]: {
    marginTop: '30px',
    marginBottom: '30px',
  },
});

interface MetricFormProps {
  onSubmit: (params: AssessmentParameters) => void;
  prefilledValues?: AssessmentParameters;
}

export function MetricForm(props: MetricFormProps) {
  const { helpers } = useSettings();
  const { t } = useTranslation(['forms', 'pages']);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    watch,
    setValue,
  } = useForm<AssessmentParameters>({
    mode: 'onBlur',
    defaultValues: { ...DEFAULT_ASSESSMENT_VALUES, height: null },
  });

  useEffect(() => {
    if (props.prefilledValues) {
      const fields = props.prefilledValues;
      const keys = Object.keys(fields) as Array<keyof AssessmentParameters>;
      keys.forEach((f) => setValue(f, fields[f]));
    }
  }, [props.prefilledValues, setValue]);
  const showDiabetesMedication = watch('diabetes');

  return (
    <Root className={classes.container}>
      <Typography variant="h4" className={classes.heading}>
        Patient data for {helpers.formatDate(new Date())} evaluation
      </Typography>
      <div>
        <Typography variant={'h6'}>{t('pages:assessment.headings.vitals')}</Typography>
        <MetricWeightHeight register={register} errors={errors} />
        <PulseBloodPressure register={register} errors={errors} />
      </div>
      <Divider />
      <MedicalHistory control={control} errors={errors} />
      <Divider className={classes.divider} />
      <Medications
        errors={errors}
        control={control}
        showDiabetesMedication={showDiabetesMedication}
      />
      <Divider className={classes.divider} />
      <ConsentSubmit submitHandler={handleSubmit(props.onSubmit)} />
    </Root>
  );
}
