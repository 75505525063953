import React from 'react';
import { styled } from '@mui/material/styles';
import { Box, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

const PREFIX = 'CURRENT_COLOR';

const classes = {
  value: `${PREFIX}-value`,
  riskRow: `${PREFIX}-riskRow`,
};

const Root = styled('div')({
  [`& .${classes.value}`]: {
    fontWeight: 'bold',
  },
  [`& .${classes.riskRow}`]: {
    margin: '0.5rem 0rem',
  },
});

export const CURRENT_COLOR = '#dda8a8';
export const ADJUSTED_COLOR = '#b2dca8';

interface IndividualRiskProps {
  title: string;
  currentRisk: number;
  adjustedRisk: number;
  maxRiskValue: number;
}

export function IndividualRisk(props: IndividualRiskProps) {
  const { t } = useTranslation('pages');

  const currentRisk = props.currentRisk * 100;
  const adjustedRisk = props.adjustedRisk * 100;

  const difference = Math.round(
    (100 * (props.adjustedRisk - props.currentRisk)) / props.currentRisk,
  );

  const getRiskString = (diff: number) => {
    if (diff === 0) return t('patientProfile.evaluation.risks.noChangedRisk');
    else if (diff < 0)
      return t('patientProfile.evaluation.risks.decreasedRisk') + `: - ${Math.abs(diff)}% `;
    else return t('patientProfile.evaluation.risks.increasedRisk') + `: + ${diff}% `;
  };

  const getRiskArrow = (diff: number) => {
    return diff < 0 ? (
      <ArrowDownwardIcon fontSize="small" sx={{ verticalAlign: 'top' }} />
    ) : (
      <ArrowUpwardIcon fontSize="small" sx={{ verticalAlign: 'top' }} />
    );
  };

  return (
    <Root sx={{ mb: 3 }}>
      <Typography variant="body1" fontWeight="bold">
        {props.title}
      </Typography>
      <Typography variant="body2">
        <Box sx={{ color: 'text.secondary', mb: 2 }}>
          {getRiskString(difference)}
          {difference !== 0 && getRiskArrow(difference)}
        </Box>
        <Grid container className={classes.riskRow}>
          <Grid item xs={3}>
            {t('patientProfile.evaluation.risks.current')}
          </Grid>
          <Grid item xs={2} className={classes.value}>
            {currentRisk.toFixed(1)}
          </Grid>
          <Grid item xs={7}>
            <Box width={currentRisk / props.maxRiskValue} bgcolor={CURRENT_COLOR} height="100%" />
          </Grid>
        </Grid>
        <Grid container className={classes.riskRow}>
          <Grid item xs={3}>
            {t('patientProfile.evaluation.risks.adjusted')}
          </Grid>
          <Grid item xs={2} className={classes.value}>
            {adjustedRisk.toFixed(1)}
          </Grid>
          <Grid item xs={7}>
            <Box width={adjustedRisk / props.maxRiskValue} bgcolor={ADJUSTED_COLOR} height="100%" />
          </Grid>
        </Grid>
      </Typography>
    </Root>
  );
}
