import React, { PropsWithChildren, useState } from 'react';
import { DocumentConfiguration } from '../../../types/content-configuration';
import { InfoIcon } from '../InfoIcon/InfoIcon';
import { getSectionComponent } from '../StyledPopup/get-section-component';
import { StyledPopup } from '../StyledPopup/StyledPopup';
import { IconButton, Link } from '@mui/material';

interface DocumentPopupProps {
  document: DocumentConfiguration;
  linkText?: string;
}

export function DocumentPopup(props: PropsWithChildren<DocumentPopupProps>) {
  const [open, setOpen] = useState(false);

  const handleOpen = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    setOpen(true);
  };

  return (
    <>
      {props.linkText ? (
        <Link onClick={handleOpen}>{props.linkText}</Link>
      ) : (
        <IconButton
          sx={{ color: (theme) => theme.palette.grey[500], top: -7 }}
          onClick={() => setOpen(true)}
        >
          <InfoIcon fontSize="small" />
        </IconButton>
      )}
      <StyledPopup title={props.document.title} open={open} handleClose={() => setOpen(false)}>
        {props.document.sections.map(getSectionComponent)}
      </StyledPopup>
    </>
  );
}
