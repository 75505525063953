import React from 'react';
import { List } from '@mui/material';
import RecommendationItem from './RecommendationItem';
import { Recommendation } from '../../../../api/patients';

interface RecommendationsProps {
  recommendations: Recommendation[];
  onChange: (recommendations: Recommendation[]) => any;
}

export default function Recommendations({ recommendations, onChange }: RecommendationsProps) {
  const handleToggle = (updatedRecommendation: Recommendation) => {
    onChange(
      recommendations.map((r) => (r.key === updatedRecommendation.key ? updatedRecommendation : r)),
    );
  };

  return (
    <List>
      {recommendations.map((recommendation, key) => (
        <React.Fragment key={key}>
          <RecommendationItem recommendation={recommendation} onToggle={handleToggle} />
        </React.Fragment>
      ))}
    </List>
  );
}
