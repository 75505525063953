import React, { useEffect, useState } from 'react';
import { Box, Button, Divider, Grid, Typography } from '@mui/material';
import Recommendations from './recommendations/Recommendations';
import Risks from './risks/Risks';
import {
  EvaluationResult,
  Recommendation,
  useAdjustedRisks,
  useEvaluationResult,
} from '../../../api/patients';
import { useTranslation } from 'react-i18next';
import { useSettings } from '../../../hooks/useSettings';
import { useNavigate } from 'react-router';
import { DocumentPopup } from '../../core/DocumentPopup/DocumentPopup';
import { DocumentSectionType } from '../../../types/content-configuration';

interface EvaluationProps {
  id: string;
  saveAndClose?: (evalData: EvaluationResult) => void;
}

const saveAndCloseLink = '/patient';

const LEFT_COLUMN_WIDTH = 5;
const RIGHT_COLUMN_WIDTH = 7;

export function Evaluation({ id, saveAndClose }: EvaluationProps) {
  const { helpers } = useSettings();
  const { t } = useTranslation('pages');
  const navigate = useNavigate();

  const [recommendations, setRecommendations] = useState<Recommendation[]>([]);
  const { isLoading, isError, data, error } = useEvaluationResult(id);
  const { data: adjustedRisks } = useAdjustedRisks(id, recommendations, Boolean(data));

  useEffect(() => {
    if (data) setRecommendations(data.recommendations);
  }, [data]);

  if (isLoading) return <div>Loading...</div>;

  if (isError || !data) return <div>Error: {error?.message}</div>;

  const defaultSaveAndClose = () => {
    navigate(saveAndCloseLink);
  };

  return (
    <Box sx={{ p: 4 }}>
      <Grid container spacing={3} sx={{ mb: 2 }}>
        <Grid item xs={LEFT_COLUMN_WIDTH}>
          <Typography variant="body2" fontWeight="bold" sx={{ mb: 2 }}>
            {t('patientProfile.evaluation.dateLabel', { date: helpers.formatDate(data.date) })}
          </Typography>
          <Typography variant="h5" fontWeight="bold" sx={{ mb: 3 }}>
            {t('patientProfile.evaluation.risks.cha2ds2vasc', {
              score: data.currentRisks.cha2ds2vascScore,
            })}
          </Typography>
          <Divider />
          <Risks
            riskHistory={{
              before: data.currentRisks,
              after: adjustedRisks ? adjustedRisks : data.adjustedRisks,
            }}
          />
        </Grid>
        <Grid item xs={RIGHT_COLUMN_WIDTH} sx={{ mt: 4.5 }}>
          <Typography variant="h5" fontWeight="bold" sx={{ mb: 1 }}>
            {t('patientProfile.evaluation.recommendations.heading')}
          </Typography>
          <Typography>
            {t('patientProfile.evaluation.recommendations.helperText')}
            <DocumentPopup
              document={{
                title: t('patientProfile.evaluation.recommendations.detailedHelpTextTitle'),
                sections: [
                  {
                    type: DocumentSectionType.paragraph,
                    content: t('patientProfile.evaluation.recommendations.detailedHelpText'),
                  },
                ],
              }}
            />
          </Typography>
          <Recommendations recommendations={recommendations} onChange={setRecommendations} />
          <Grid container justifyContent="flex-end">
            <Button
              variant="contained"
              color="primary"
              onClick={() =>
                saveAndClose !== undefined ? saveAndClose(data) : defaultSaveAndClose()
              }
            >
              Save and Close
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
