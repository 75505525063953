import { Typography } from '@mui/material';
import React from 'react';
import { DocumentSection, DocumentSectionType } from '../../../types/content-configuration';

export function getSectionComponent(section: DocumentSection, key: number) {
  switch (section.type) {
    case DocumentSectionType.title: {
      return (
        <Typography variant={'h6'} style={{ marginTop: '20px' }} key={key}>
          {section.content}
        </Typography>
      );
    }
    case DocumentSectionType.text: {
      return <Typography key={key}>{section.content}</Typography>;
    }
    case DocumentSectionType.paragraph: {
      return (
        <Typography gutterBottom key={key}>
          {section.content}
        </Typography>
      );
    }
    case DocumentSectionType.boldParagraph: {
      return (
        <Typography gutterBottom style={{ fontWeight: 'bold' }} key={key}>
          {section.content}
        </Typography>
      );
    }
    case DocumentSectionType.link: {
      return (
        <Typography gutterBottom key={key}>
          <a
            href={section.content}
            target="_blank"
            rel="noopener noreferrer"
            style={{ marginBottom: '20px' }}
          >
            {section.content}
          </a>
        </Typography>
      );
    }
  }
}
