import { TFunction } from 'i18next';

interface MinMaxType {
  [key: string]: {
    min: number;
    max: number;
  };
}

export function ruleHelper<T extends MinMaxType>(
  t: TFunction,
  allowedValues: T,
  key: keyof T,
  required: boolean = false,
  disabled?: boolean,
) {
  const req = required ? { required: t('errors.required') } : {};
  return {
    setValueAs: (value: string) => (value === '' || value === null ? null : parseInt(value)),
    ...req,
    min: {
      value: allowedValues[key].min,
      message: t('errors.between', { min: allowedValues[key].min, max: allowedValues[key].max }),
    },
    max: {
      value: allowedValues[key].max,
      message: t('errors.between', { min: allowedValues[key].min, max: allowedValues[key].max }),
    },
    disabled: disabled,
  };
}
