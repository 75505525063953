import React from 'react';
import { styled } from '@mui/material/styles';
import { Container, Grid, Link, Paper, Typography } from '@mui/material';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { NavigateBefore } from '@mui/icons-material';
import { useNavigate } from 'react-router';
import { usePatientById } from '../../api/patients';
import { MedicalData } from './MedicalData';
import { PrefilledLatestAssessment } from './PrefilledLatestAssessment';
import { AssessmentParameters, assessmentsApi } from '../../api/assessments';
import { useURLQuery } from '../../hooks/useURLQuery';
import { isRedoxPage } from '../../utils/isRedoxPage';
import { PrefilledRedoxAssessment } from './PrefilledRedoxAssessment';

const PREFIX = 'Assessment';

const classes = {
  container: `${PREFIX}-container`,
  header: `${PREFIX}-header`,
  name: `${PREFIX}-name`,
};

const StyledContainer = styled(Container)(({ theme }) => ({
  [`&.${classes.container}`]: {
    padding: '1rem',
  },

  [`& .${classes.header}`]: {
    backgroundColor: theme.palette.primary.main,
    padding: '1rem 3rem',
    margin: 0,
  },

  [`& .${classes.name}`]: {
    color: theme.palette.primary.contrastText,
    margin: 0,
  },
}));

interface RouteParams {
  patientId: string;
  assessmentId: string;
}

export function Assessment() {
  const navigate = useNavigate();

  const { patientId, assessmentId } = useParams() as RouteParams;
  const query = useURLQuery();
  const sessionId = query.get('session') || '';
  const id = isRedoxPage() ? sessionId : patientId;

  const { isLoading, isError, data: patient, error } = usePatientById(id);

  if (isLoading) return <div>Loading...</div>;

  if (isError || !patient) return <div>Error: {error?.message}</div>;

  const onCreateAssessment = async (params: AssessmentParameters) => {
    await assessmentsApi.create(params, patient.id);
    navigate(`/patient/${patient.id}/evaluation/risk`);
  };

  return (
    <StyledContainer maxWidth="md" className={classes.container}>
      <Paper elevation={0}>
        <div className={classes.header}>
          {isRedoxPage() ? (
            <Typography variant="h6" className={classes.name}>
              {`${patient.firstName} ${patient.lastName}`}
            </Typography>
          ) : (
            <Link component={RouterLink} to="/patient" variant="h6" className={classes.name}>
              <Grid container>
                <NavigateBefore />
                <div>{`${patient.firstName} ${patient.lastName}`}</div>
              </Grid>
            </Link>
          )}
        </div>
        {assessmentId === 'latest' ? (
          <PrefilledLatestAssessment id={id} />
        ) : isRedoxPage() ? (
          <PrefilledRedoxAssessment sessionId={sessionId} />
        ) : (
          <MedicalData onSubmit={onCreateAssessment} />
        )}
      </Paper>
    </StyledContainer>
  );
}
