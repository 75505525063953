import React from 'react';
import Grid from '@mui/material/Grid';
import { StyledTextField } from '../../core/input-components/StyledTextField';
import { useTranslation } from 'react-i18next';
import { FieldErrors, Path, UseFormRegister } from 'react-hook-form';
import { ruleHelper } from './rule-helper';

interface Props<T> {
  register: UseFormRegister<T>;
  errors: FieldErrors;
}
const ALLOWED_VALUES = {
  weight: {
    min: 30,
    max: 400,
  },
  height: {
    min: 100,
    max: 240,
  },
};

export function MetricWeightHeight<T>(props: Props<T>) {
  const { t } = useTranslation('forms');

  return (
    <Grid container spacing={5}>
      <Grid item xs={4}>
        <StyledTextField
          name="weight"
          type="number"
          label={t('vitals.weight')}
          register={props.register('weight' as Path<T>, ruleHelper(t, ALLOWED_VALUES, 'weight'))}
          errors={props.errors}
          adornment="kg"
        />
      </Grid>
      <Grid item xs={4}>
        <StyledTextField
          name="height"
          type="number"
          label={t('vitals.height')}
          register={props.register('height' as Path<T>, ruleHelper(t, ALLOWED_VALUES, 'height'))}
          errors={props.errors}
          adornment="cm"
        />
      </Grid>
    </Grid>
  );
}
