import { SvgIcon } from '@mui/material';
import React from 'react';

export function InfoIcon(props: any) {
  return (
    <SvgIcon {...props}>
      <path d="M12 4c-4.41 0-8 3.59-8 8s3.59 8 8 8 8-3.59 8-8-3.59-8-8-8zm1 13h-2v-6h2v6zm0-8h-2V7h2v2z" />
      <path d="m 11,7 h 2 v 2 h -2 z m 0,4 h 2 v 6 h -2 z" fill={'black'} />
    </SvgIcon>
  );
}
