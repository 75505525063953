import { AssessmentParameters, DiabetesMedication, OralAnticoagulant } from '../../api/assessments';
import { useSettings } from '../../hooks/useSettings';
import { MetricForm } from './MetricForm';
import { ImperialForm, ImperialParameters } from './ImperialForm';
import { Units } from '../../context/SettingsProvider';
import React from 'react';
import { convertImperialToMetricUnits, convertMetricToImperialUnits } from '../../utils/units';

interface MedicalDataProps {
  onSubmit: (params: AssessmentParameters) => void;
  prefilledValues?: AssessmentParameters;
}

export const DEFAULT_ASSESSMENT_VALUES = {
  weight: null,
  pulse: null,
  diastolicBloodPressure: null,
  atrialFibrillation: true,
  historyOfBleeding: false,
  coronaryArteryDisease: false,
  carotidArteryDisease: false,
  chronicKidneyDisease: false,
  dementia: false,
  diabetes: false,
  hypertension: false,
  heartFailure: false,
  peripheralArteryDisease: false,
  currentSmoker: false,
  historyOfStroke: false,
  transientIschemicAttack: false,
  thromboembolism: false,

  antiplateletMedication: false,
  oralAnticoagulant: OralAnticoagulant.NONE,
  diabetesMedication: DiabetesMedication.NONE,
};

export function MedicalData(props: MedicalDataProps) {
  const { settings } = useSettings();

  const imperialSubmit = (data: ImperialParameters) => {
    props.onSubmit(convertImperialToMetricUnits(data));
  };

  if (settings.units === Units.Metric) {
    return <MetricForm onSubmit={props.onSubmit} prefilledValues={props.prefilledValues} />;
  } else {
    const values = props.prefilledValues
      ? convertMetricToImperialUnits(props.prefilledValues)
      : undefined;
    return <ImperialForm onSubmit={imperialSubmit} prefilledValues={values} />;
  }
}
