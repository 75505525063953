import { ImperialParameters } from '../components/assessment/ImperialForm';
import { AssessmentParameters } from '../api/assessments';

const KG_PER_POUND = 0.45359237;
const CM_PER_FOOT = 30.48;
const CM_PER_INCH = 2.54;
const INCHES_PER_FOOT = 12;

export function convertImperialToMetricUnits({
  feet,
  inches,
  weight: imperialWeight,
  ...params
}: ImperialParameters): AssessmentParameters {
  let height;
  if (feet === null && inches === null) {
    height = null;
  } else {
    height = 0;
    if (feet !== null) height += feet * CM_PER_FOOT;
    if (inches !== null) height += inches * CM_PER_INCH;
  }
  return {
    ...params,
    weight: imperialWeight === null ? null : imperialWeight * KG_PER_POUND,
    height,
  };
}
export function convertMetricToImperialUnits({
  weight: metricWeight,
  height: metricHeight,
  ...params
}: AssessmentParameters): ImperialParameters {
  let inches, feet;
  if (metricHeight === null) {
    inches = null;
    feet = null;
  } else {
    inches = Math.round(metricHeight / CM_PER_INCH);
    feet = Math.floor(inches / INCHES_PER_FOOT);
    inches = inches % INCHES_PER_FOOT;
  }

  return {
    ...params,
    weight: metricWeight === null ? null : Math.round(metricWeight / KG_PER_POUND),
    feet,
    inches,
  };
}
