import React from 'react';
import { InputAdornment, TextField } from '@mui/material';
import { FieldErrors, UseFormRegisterReturn } from 'react-hook-form';

interface StyledTextFieldProps {
  name: string;
  label: string;
  type?: string;
  register: UseFormRegisterReturn;
  errors: FieldErrors;
  adornment?: string;
  placeholder?: string;
  disabled?: boolean;
}

export function StyledTextField(props: StyledTextFieldProps) {
  const { ref, ...register } = props.register;
  return (
    <TextField
      {...register}
      placeholder={props.placeholder}
      id={props.name}
      name={props.name}
      label={props.label}
      type={props.type}
      disabled={props.disabled}
      variant="filled"
      fullWidth={true}
      inputRef={ref}
      error={props.errors[props.name]?.message !== undefined}
      helperText={props.errors[props.name]?.message || ' '}
      InputProps={{
        endAdornment: <InputAdornment position="end">{props.adornment ?? ''}</InputAdornment>,
      }}
    />
  );
}
